@import "../style.scss";

@import "../../../node_modules/bulma/sass/grid/tiles.sass";

// Required Core Stylesheet
@import "../../../node_modules/@glidejs/glide/src/assets/sass/glide.core";

// Optional Theme Stylesheet
@import "../../../node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.glide__bullets {
   bottom: 0em;
}

.glide__bullet {
 background-color: #8C1515;
 width: 12px;
 height: 12px;
}

.glide__bullet--active {
  background-color: #B83A4B;
}

.svl-home--client-logo {
  max-width: 150px;
}

.svl-home--team-pic {
  max-width: 350px;
}

.svl-home--upper-padding {
  padding-top: 1.5rem;
}

.svl-home--section_top {
  padding-bottom: 2rem;
}

.svl-home--hero {
  padding-top: 0;
  padding-bottom: 0;

  .svl-home--hero_left {
    padding-top: 3rem;
  }
}

