@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Montserrat:700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

$black: rgb(37, 37, 37);
$black-dimmed: rgb(100, 100, 100);
$gray: rgb(245, 245, 245);
$gray-dark: rgb(200, 200, 200);
$red: rgb(177, 6, 14);

$family-serif: 'Montserrat', serif;
$family-sans-serif: "Open Sans", sans-serif;

$box-background-color: transparent;
$box-shadow: 0 0 0 transparent 0 0 0 0 transparent;
$box-padding: 0;
$dimensions: 480 600;
$link: $red;
$tabs-toggle-link-radius: 2px;

@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/components/menu.sass";
@import "../../node_modules/bulma/sass/components/media.sass";
@import "../../node_modules/bulma/sass/components/navbar.sass";
@import "../../node_modules/bulma/sass/components/tabs.sass";
@import "../../node_modules/bulma/sass/elements/box.sass";
@import "../../node_modules/bulma/sass/elements/container.sass";
@import "../../node_modules/bulma/sass/elements/image.sass";
@import "../../node_modules/bulma/sass/elements/title.sass";
@import "../../node_modules/bulma/sass/grid/columns.sass";
@import "../../node_modules/bulma/sass/layout/section.sass";
@import "../../node_modules/bulma/sass/helpers/visibility.sass";

.mono {
  font-size: 0.9rem;
  color: $black;
}

.navbar {
  .navbar-item {
    font-size: 1.05rem;

    &:hover {
      background-color: white;
    }

    img {
      max-height: 4rem;
    }
  }

  .active {
    padding: 0;
    color: $red;

    .svl-navbar-item-container {
      padding: 0.25rem 1rem;
      width: 100%;
      border-left: 1px solid $red;
      border-right: 1px solid $red;
    }
  }
}

@media screen and (min-width: 960px + (2 * $gap)) {
  .navbar {
    height: 95px;
  }
}

.footer {
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.has-text-right-on-desktop {
  text-align: right;

  @media screen and (max-width: 960px + (2 * $gap)) {
    text-align: inherit;
  }
}

.is-4 {
  color: $red;
}

.section:nth-child(even) {
  background-color: $gray;
}

.svl-img_logo {
  width: 60px;
}
